<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card class="primary--border" outlined>
          <v-card-title class="title">
            SMS Report
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-title class="title">
            <v-flex xs4>
              <v-calendar-field
                id-val="from_date"
                v-model="frmDate"
                label="From Date"
              >
              </v-calendar-field>
            </v-flex>
            <v-flex xs4>
              <v-calendar-field
                id-val="to_date"
                v-model="toDate"
                label="To Date"
              >
              </v-calendar-field>
            </v-flex>
            <search-button permission="batch-create" @action="get()">
              Search
            </search-button>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            :server-items-length="form.items.meta.total"
            sort-by="id"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">{{ item.created_at }}</td>
                <td class="text-xs-left">{{ item.sms_to }}</td>
                <td class="text-xs-left" width="50%">{{ item.message }}</td>
                <td class="text-xs-center">
                  <v-badge v-if="item.status === 'sent'"
                    ><v-icon color="green">check_circle</v-icon></v-badge
                  >
                  <v-badge v-if="item.status === 'error'"
                    ><v-icon color="error">cancel</v-icon></v-badge
                  >
                  <v-badge
                    v-if="item.status === 'queue'"
                    style="background: #5fbae9;color: white;border-radius: 15px;padding: 5px"
                    >queue</v-badge
                  >
                  <!--                                <v-badge :class="props.item.status === 'sent' ?  'badge-green' : (props.item.status === 'error' ? 'badge-red' :'badge-yellow' )">{{props.item.status}}</v-badge></td>-->
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
// const NepaliDate = require('nepali-date');

export default {
  data: () => ({
    isLoading: false,
    co_ordinator: [],
    model: null,
    entries: [],
    form: new Form(
      {
        title: "",
        message: "",
      },
      "/api/sms/queue"
    ),
    search: "",
    searchCo: "",
    teacherList: [],
    pagination: {
      rowsPerPage: 50,
    },
    frmDate: "",
    toDate: "",
    headers: [
      { text: "#", align: "left", value: "id", width: 50, sortable: false },
      { text: "Date", align: "left", value: "date" },
      { text: "Mobile", align: "left", value: "mobile" },
      { text: "Message", align: "left", value: "Message" },
      { text: "Status", align: "center", sortable: false },
    ],
  }),

  computed: {
    ...mapState(["batch"]),
  },

  watch: {
    pagination: function() {
      //   this.get();
    },
    batch: function(value) {
      //   this.get();
    },
  },
  methods: {
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map(function(key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
              );
          })
          .join("&") +
        "&fromDate=" +
        this.dateFormat(this.frmDate) +
        "&toDate=" +
        this.dateFormat(this.toDate)
      );
    },
    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },
    dateFormat(data) {
      if (data.length === 8)
        return (data =
          data.substr(0, 4) + "-" + data[4] + data[5] + "-" + data.slice(-2));
      else return data;
    },
  },
};
</script>
<style lang="scss" scoped>
.badge-green {
  background: green;
  color: white;
  border-radius: 15px;
  padding: 5px;
}

.badge-red {
  background: red;
  color: white;
  border-radius: 15px;
  padding: 5px;
}

.badge-yellow {
  background: yellow;
  color: white;
  border-radius: 15px;
  padding: 5px;
}
</style>
